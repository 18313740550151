<template>
  <div>
    <el-row :gutter="20" v-if="!loading">
      <el-col :md="12" :lg="6" :sm="24" :xs="24" v-for="item in data" :key="item.name">
        <div v-html="mapResourceCard(item)"></div>
      </el-col>
    </el-row>

    <el-row :gutter="20" v-else>
      <el-col :md="12" :lg="6" :sm="24" :xs="24" v-for="(item, index) in 4" :key="index">
        <div class="infra-card">
          <content-placeholders>
            <content-placeholders-text :lines="3" />
          </content-placeholders>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { infra } from "api/monitor";
import i18n from "@/lang";

export default {
  props: {
    vendor: {
      type: String
    },
    cluster: {
      type: String
    },
    region: {
      type: String
    }
  },

  data() {
    return {
      data: [],
      loading: true,
      titleObj: {
        cpuTotal: i18n.t("cpuTotal"),
        memoryTotal: i18n.t("memoryTotal"),
        diskTotal: i18n.t("diskTotal"),
        podTotal: i18n.t("podTotal")
      }
    };
  },

  methods: {
    init() {
      this.loading = true;
      infra({ vendor: this.vendor, region: this.region, cluster: this.cluster }).then(response => {
        if (response.code === 0) {
          this.loading = false;
          this.data = response.data.resourceTotal;
        }
      });
    },

    toFixed(percent) {
      return Number(Number(percent).toFixed(0));
    },

    mapTitle(name) {
      return this.titleObj[name];
    },

    mapColor(num) {
      let color = "";
      if (num < 50) {
        color = "#257adf";
      } else if (num > 50 && num < 70) {
        color = "#e17a4c";
      } else {
        color = "#fc3d26";
      }

      return color;
    },

    mapResourceCard(item) {
      let unit = item.unit;
      let cluster = item.cluster[0];

      let usage = Number((cluster.percent * 100).toFixed(0));
      let request = Number((cluster.requestPercent * 100).toFixed(0));

      let requestTem =
        request > 0
          ? `
            <span>${request}%</span>
            <span>(${cluster.request}${unit})</span>`
          : "-";

      return `
        <div class="infra-card" style="background: ${this.mapColor(request)}">
          <div class="title">${this.mapTitle(item.name)}: ${item.value} ${unit}</div>

          <div class="infra-progress">
            ${this.mapProgress(usage, request)}
          </div>

          <div class="cluster-usage">
            <span>${this.$t("requestedResource")}：</span>
            ${requestTem}
          </div>

          <div class="cluster-usage">
            <span>${this.$t("consumedResource")}：</span>
            <span>${usage}%</span>
            <span>(${cluster.usage}${unit})</span>
          </div>

        </div>
      `;
    },

    mapProgress(usage, request) {
      let usageWidth = usage > 100 ? 100 : usage;
      let requestWidth = request > 100 ? 100 : request;
      return `
        <div class="usage _inner" style="width: ${usageWidth}%"></div>
        <div class="request _inner" style="width: ${requestWidth}%"></div> 
      `;
    }
  },

  mounted() {
    this.init();
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";
@import "~@/styles/common.scss";

.infra-card {
  @include dashboard-card(#fff);
  margin-bottom: 20px;

  .title {
    font-size: 16px;
  }

  .cluster-usage {
    @include text-overflow();
    font-size: 12px;
    color: #c9d0ed;
    margin-bottom: 6px;
    position: relative;
  }
}

.infra-progress {
  @include progress();
  height: 20px;
  background: rgba($color: #000, $alpha: 0.2);
  margin-bottom: 20px;

  .usage {
    background: rgba($color: #fff, $alpha: 0.9);
    z-index: 10;
  }

  .request {
    background: rgba($color: #fff, $alpha: 0.5);
    z-index: 11;
  }
}
</style>
