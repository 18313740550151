<template>
  <div>
    <div class="dashboard-title">
      <i class="el-icon-data-analysis"></i>
      {{ $t("clusterMonitor") }}
    </div>

    <el-tabs v-model="activeName" type="card" class="cluster-tab" @tab-click="tabClick">
      <el-tab-pane :label="item.cluster" :name="item.cluster" v-for="item in providers" :key="item.cluster">
        <div class="current-cluster-info">
          <div>
            <span class="name">{{ $t("vendor") }}：</span>
            <span class="value">{{ item.kind }}</span>
          </div>

          <div>
            <span class="name">{{ $t("region") }}：</span>
            <span class="value">{{ item.regionID }}</span>
          </div>

          <div>
            <span class="name">{{ $t("Cluster") }}：</span>
            <span class="value">{{ item.cluster }}</span>
          </div>

          <div>
            <span class="name">{{ $t("status") }}：</span>
            <div class="status success" v-if="item.ready">Ready</div>
            <div class="status danger" v-else>NotReady</div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <div v-if="!loading">
      <infra-usage :vendor="info.vendor" :region="info.region" :cluster="info.cluster" />
      <cluster-monitor
        :vendor="info.vendor"
        :region="info.region"
        :cluster="info.cluster"
        style="margin-bottom: 20px;"
      />
    </div>
  </div>
</template>

<script>
import InfraUsage from "./InfraUsage";
import ClusterMonitor from "./ClusterMonitor";
export default {
  components: {
    InfraUsage,
    ClusterMonitor
  },

  data() {
    return {
      info: {},
      loading: true,
      activeName: ""
    };
  },

  methods: {
    tabClick() {
      this.loading = true;

      this.findCluster(this.activeName);

      this.$nextTick(() => {
        this.loading = false;
      });
    },

    findCluster(cluster) {
      for (let item of this.providers) {
        if (item.cluster === cluster) {
          this.info = {
            vendor: item.kind,
            region: item.regionID,
            cluster: item.cluster
          };
        }
      }
    }
  },

  computed: {
    providers() {
      return this.$store.state.app.userInfo.providers;
    }
  },

  mounted() {
    this.loading = true;

    if (this.$route.query.cluster) {
      this.findCluster(this.$route.query.cluster);
    } else {
      this.info = {
        vendor: this.providers[0].kind,
        region: this.providers[0].regionID,
        cluster: this.providers[0].cluster
      };
    }

    this.activeName = this.info.cluster;

    this.loading = false;
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.current-cluster-info {
  margin-bottom: 20px;
  overflow: hidden;

  @include flex(flex-start);

  & > div {
    @include flex(flex-start);
    margin-right: 20px;

    .name {
      color: $color-sub;
      font-size: 12px;
    }

    .value {
      @include title(14px);
    }
  }
}

.dashboard-title {
  @include title(18px);
  margin-bottom: 20px;

  i {
    font-size: 20px;
    color: #257adf;
  }
}

.cluster-tab.el-tabs--card {
  & > .el-tabs__header {
    border-color: #c1c8cd;

    .el-tabs__nav {
      border: none;
    }

    .el-tabs__item {
      background-color: #cedae7;
      color: #fff;
      margin-right: 8px;
      border-radius: 4px 4px 0 0;
      padding: 0 30px;
      font-size: 14px;
      font-weight: bolder;
      text-align: center;

      &.is-active {
        background-color: #257adf;
      }
    }
  }
}
</style>
